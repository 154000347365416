<template>
  <div id="invoiceBindDialog">
    <el-dialog
      title="开票信息绑定"
      :visible.sync="show"
      @closed="reset"
      @open="getShopInvoice"
    >
      <el-form :model="form" :rules="formRules" ref="form" label-width="100px">
        <el-form-item label="发票抬头" prop="shopInvoiceId">
          <el-select v-model="form.shopInvoiceId" placeholder="请选择发票抬头">
            <el-option
              v-for="item of shopInvoiceList"
              :key="item.key"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      shopInvoiceList: [],
      form: { shopInvoiceId: "" },
      formRules: {
        shopInvoiceId: [
          { required: true, message: "请选择发票抬头", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    async getShopInvoice() {
      let res = await this.$http.get("/invoice/authInfo/list/all");
      if (res.code === 0) {
        for (let item of res.data) {
          item.label = `${item.taxNum}(${item.clerk})`;
        }
        this.shopInvoiceList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    reset() {
      this.id = null;
      this.$set(this, "form", {});
      this.$refs["form"].resetFields();
    },
    confirmBtn() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let res = await this.$http.post("/parking/invoice/bind", {
              parkingLotId: this.$route.params.id,
              ...this.form,
            });
            if (res.code === 0) {
              this.$message.success("绑定成功");
              this.$emit("success");
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
          } catch (err) {
            this.$message.error(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
#invoiceBindDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
